<template>
	<div class="details details--opportunity details-opportunity">
		<div class="details-section">
			<div class="details-row" :class="{'details-row--col-2': !isAdmin && isEntity, 'details-row--col-3': isAdmin || !isEntity}">
				<detail label="Borrower Name" :value="opportunity.name" :border="isAdmin" />
				<detail label="Organization" :value="organization | organization" v-if="isAdmin" />
				<detail label="User" :value="format('user', opportunity.client)" v-if="isAdmin" />
				<detail label="Loan Amount" :value="opportunity.loanAmount | currency(false, 0)" :border="isAdmin" />
				<!-- <detail label="Index" :value="opportunity.loanIndex | enums('LoanIndex')" /> -->
				<!-- <detail label="Bus Conv" :value="opportunity.loanIndex" /> -->
				<detail label="Var Confidence" :value="opportunity.varConfidence | percentage(false, 1)" :border="true" v-if="isAdmin" />
				<detail label="Hedge Fee" :value="opportunity.feeHedge | basisPoints" :border="isAdmin && !isEntity" v-if="!isEntity" />
				<detail label="Institutional Markup" :value="institutionalMarkup | basisPoints" :border="!!isEntity" v-if="isAdmin" />
				<detail label="Discretionary Fee" :value="opportunity.feeDiscretionary | basisPoints" :border="false" v-if="isAdmin" />
			</div>
		</div>
	</div>
</template>

<script>
import Detail from '@/components/Detail'

export default {
	name: 'DetailsOpportunity',
	components: {
		Detail,
	},
	props: {
		opportunity: {
			type: Object,
			default: () => ({}),
		},
	},
	computed: {
		isAdmin() {
			return !!this.$store.getters['user/isAdmin']
		},
		institutionalMarkup() {
			return Number(this.opportunity.feeMarkup) + Number(this.opportunity.feeExternal || 0)
		},
		organization() {
			if (this.opportunity.client) {
				return this.opportunity.client.organization
			}
			return {}
		},
		me() {
			return this.$store.getters['user/me']
		},
		isEntity() {
			if (!((this.opportunity || {}).client || {}).organization) {
				return ((this.me.organization || {}).settings || {}).entity
			}
			return ((((this.opportunity || {}).client || {}).organization || {}).settings || {}).entity
		},
	},
	methods: {
		format(key, value) {
			if (!value) return
			let _format = value
			switch (key) {
				case 'organization':
					_format = value.name
					if (value.address) {
						_format += ` (${[value.address.city, value.address.state].join(', ')})`
					}
					return _format
				case 'user':
					_format = [value.firstName, value.lastName].join(' ')
					return _format
			}

			return _format
		},
	},
}
</script>

<style scoped lang="scss">

</style>
